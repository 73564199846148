import React from "react";
import { Link } from "gatsby";
//import logo from "../images/logo.png"
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Navbar = ({ toggleSidebar }) => {
  const data = [
    {
      id: 1,
      text: "STORE",
      url: "/store/",
    },
    {
      id: 2,
      text: "fAQ",
      url: "/faq/",
    },
    {
      id: 3,
      text: "CONTACT",
      url: "/contact/",
    },
  ];

  const contentfulData = useStaticQuery(graphql`
    {
      contentfulLogo {
        image {
          fixed(width: 128, height: 50) {
            src
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  `);

  const img = contentfulData.contentfulLogo.image.fixed;

  return (
    <nav className="fixed-nav shadow-md">
      <div className="max-w-screen-lg xl:max-w-screen-xl my-6 xl:mx-auto lg:mx-12 md:mx-8 mx-6">
        <div className="flex justify-between items-center">
          <button
            onClick={toggleSidebar}
            className="lg:hidden focus:outline-none text-3xl bg-transparent border-transparent cursor-pointer"
            aria-label="Hamburger Menu"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
          <Link className="lg:hidden" to="/">
            <Img fixed={img} alt="logo" />
          </Link>
          <ul className="hidden lg:flex lg:items-center">
            <Link to="/">
              <Img fixed={img} alt="logo" width="100%" height="40" />
            </Link>
            {data.map((link) => {
              return (
                <li key={link.id} className="pl-12">
                  <Link
                    to={link.url}
                    activeStyle={{ color: "#D97706" }}
                    className="capitalize font-normal lg:py-2 lg:px-1 text-sm lg:text-base lg:hover:text-amber-600 lg:transition-all lg:duration-300 lg:ease-linear"
                  >
                    {link.text}
                  </Link>
                </li>
              );
            })}
          </ul>
          <ul className="hidden lg:flex">
            <li>
              {/*Cart Icon*/}
              <div className="pr-8 pl-5 cursor-pointer text-base relative snipcart-checkout">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                  ></path>
                </svg>

                <div className="bg-black absolute -top-2 right-5 rounded-full w-5 h-5 text-white text-xs flex justify-center items-center">
                  <span className="snipcart-items-count">0</span>
                </div>
              </div>
            </li>
            <li>
              {/*Login Icon*/}
              <div className="snipcart-customer-signin pl-2 cursor-pointer text-base flex hover:text-amber-600 transition-all duration-300 ease-linear">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  ></path>
                </svg>

                <div className="pl-1">My Account</div>
              </div>
            </li>
          </ul>

          <div className="lg:hidden flex justify-end items-center my-auto">
            {/*Cart Icon*/}
            <div className="cursor-pointer text-base relative snipcart-checkout">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                ></path>
              </svg>

              <div className="bg-black absolute -top-2 -right-3 rounded-full w-5 h-5 text-white text-xs flex justify-center items-center">
                <span className="snipcart-items-count">0</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
