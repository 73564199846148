import React from "react"
//import { FaTimes } from "react-icons/fa"
//import { GrClose } from "react-icons/gr"
import { Link } from "gatsby"

const Sidebar = ({ toggleSidebar, isOpen }) => {
  const data = [
    {
      id: 1,
      text: "HOME",
      url: "/",
    },
    {
      id: 2,
      text: "STORE",
      url: "/store/",
    },
    {
      id: 3,
      text: "FAQ",
      url: "/faq/",
    },
    {
      id: 4,
      text: "CONTACT",
      url: "/contact/",
    },
  ]

  return (
    <aside className={`sidebar ${isOpen ? "show-sidebar" : ""}`}>
      {/*Login Icon*/}
      <div className="login-btn">
        <button
          onClick={toggleSidebar}
          className="snipcart-customer-signin pl-2 focus:outline-none hover:text-blue-400 transition-all duration-300 ease-linear cursor-pointer text-base flex"
        >
          <svg
            class="w-7 h-7"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            ></path>
          </svg>

          <div className="pl-1 text-lg font-medium">My Account</div>
        </button>
      </div>
      <button
        onClick={toggleSidebar}
        className="close-btn"
        aria-label="Close Button"
      >
        <svg
          class="w-7 h-7"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div className="side-container">
        <div className={`${isOpen ? "sidebar-links" : ""}`}>
          {data.map(link => {
            return (
              <ul className="">
                <li key={link.id} className="opacity-0">
                  <Link
                    to={link.url}
                    className="block font-light text-center capitalize text-gray-600  mb-5 text-3xl transition-all duration-300 ease-linear  hover:text-blue-400"
                  >
                    {link.text}
                  </Link>
                </li>
              </ul>
            )
          })}
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
