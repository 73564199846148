import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulFooter {
        id
        logo {
          fluid(maxHeight: 200) {
            src
            ...GatsbyContentfulFluid_withWebp
          }
        }
        companyName
        streetAddress
        city
        state
        zipCode
        country
        copyrightDescription
        instagramUrl
        facebookUrl
        twitterUrl
        youtubeUrl
      }
    }
  `);
  const {
    companyName,
    streetAddress,
    city,
    state,
    zipCode,
    country,
    emailAddress,
    phoneNumber,
    copyrightDescription,
    instagramUrl,
    facebookUrl,
    twitterUrl,
    youtubeUrl,
  } = data.contentfulFooter;
  const img = data.contentfulFooter.logo.fluid;

  return (
    <section className="bg-black">
      <div className="md:pt-20 md:pb-20 pt-16 pb-12">
        <div className="flex flex-col xl:flex-row items-center justify-center flex-wrap lg:justify-between pb-12 mx-8 md:mx-20">
          <Link to="/">
            <Img
              fluid={img}
              alt="logo"
              width="80px"
              height="80px"
              className="w-40 h-28 xl:w-36 xl:h-24"
            />
          </Link>

          <ul className="hidden md:flex justify-start items-center flex-wrap space-x-5  md:space-x-8 text-sm md:text-lg text-white pt-6 pb-14 lg:pb-14 xl:pb-0">
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/about-us">About Us</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/store">Store</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/careers">Careers</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/contact">Help</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
          <ul className="md:hidden pt-4 text-center space-y-2 text-base md:text-lg xl:text-xl text-white pb-14 lg:pb-14 xl:pb-0">
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/about-us">About Us</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/store">Store</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/careers">Careers</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/contact">Help</Link>
            </li>
            <li className="hover:text-amber-600 transition-all duration-300 ease-linear">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
          <div className="text-gray-100 font-light tracking-wide text-sm md:text-base xl:text-xl text-center">
            <h1 className="font-normal text-base pb-2">{companyName}</h1>
            <p className="xl:text-base">
              {streetAddress} <br />
              {city}, {state} {zipCode}, {country}
            </p>
            <p className="pt-3 md:pt-5 xl:text-base">{emailAddress}</p>
            <p className="pt-3 md:pt-5 xl:text-base">{phoneNumber}</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between pt-5 border-t-2 border-gray-300 mx-10 md:mx-20">
          <p className="text-sm md:text-base md:text-center lg:text-left pb-5 lg:pb-0 text-white">
            {copyrightDescription}
          </p>
          <div className="flex items-center font-light text-xs md:text-sm xl:text-base justify-center space-x-8 text-white">
            <a href={facebookUrl} className="">
              <FaFacebook className="text-xl xl:text-2xl hover:text-amber-500 transition-all duration-300 ease-linear" />
            </a>
            <a href={twitterUrl} className="">
              <FaTwitter className="text-xl xl:text-2xl hover:text-amber-500 transition-all duration-300 ease-linear" />
            </a>
            <a href={instagramUrl} className="">
              <FaInstagram className="text-xl xl:text-2xl hover:text-amber-500 transition-all duration-300 ease-linear" />
            </a>
            <a href={youtubeUrl} className="">
              <FaYoutube className="text-xl xl:text-2xl hover:text-amber-500 transition-all duration-300 ease-linear" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
